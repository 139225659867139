import dayjs from 'dayjs';
import { WEEK_STARTS_ON } from '../../constants';

export function endOfWeek(inputDate) {
  const date = inputDate || dayjs();
  const day = date.get('day');
  const diff = (day < WEEK_STARTS_ON ? -7 : 0) + 6 - (day - WEEK_STARTS_ON);

  return date.add(diff, 'days').hour(23).minute(59).second(59).millisecond(999);
}
