import dayjs from 'dayjs';
import { WEEK_STARTS_ON } from '../../constants';

export function startOfWeek(inputDate) {
  const date = inputDate || dayjs();
  const day = date.get('day');
  const diff = (day < WEEK_STARTS_ON ? 7 : 0) + day - WEEK_STARTS_ON;

  return date.subtract(diff, 'days').hour(0).minute(0).second(0).millisecond(0);
}
