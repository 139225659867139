import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useTimeOfDay = ({ happening }) => {
  const timeOfDay = useMemo(() => {
    const date = dayjs(happening);

    if (date.isValid()) {
      const time = date.format('HH00');

      switch (time) {
        case '0500':
          return {
            startDate: date.hour(5).minute(0),
            endDate: date.hour(12).minute(0),
            timeOfDay: {
              value: '0500',
              label: 'Morning',
            },
          };
        case '1200':
          return {
            startDate: date.hour(12).minute(0),
            endDate: date.hour(17).minute(0),
            timeOfDay: {
              value: '1200',
              label: 'After noon',
            },
          };
        case '1700':
          return {
            startDate: date.hour(17).minute(0),
            endDate: date.hour(21).minute(0),
            timeOfDay: {
              value: '1700',
              label: 'Evening',
            },
          };
        case '2100':
          return {
            startDate: date.hour(21).minute(0),
            endDate: date.hour(4).minute(0).add(1, 'day'),
            timeOfDay: {
              value: '2100',
              label: 'Night',
            },
          };

        default:
          return {};
      }
    }

    return {};
  }, [happening]);

  return timeOfDay;
};
