import dayjs from 'dayjs';

export function endOfMonth(inputDate) {
  const date = (inputDate || dayjs())
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(999);

  const month = date.get('month');
  const nextMonth = month + 1;
  if (month === 11) {
    return date.date(31);
  }
  return date.date(1).month(nextMonth).subtract(1, 'day');
}
