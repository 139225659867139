import dayjs from 'dayjs';

export function endOfThisSunday(inputDate) {
  const date = inputDate || dayjs();

  const day = date.get('day');
  let delta;
  delta = 7 - day;
  if (day === 0) delta = 0;

  return date
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(999)
    .add(delta, 'days');
}
