import AppAvatar from '@ictlife/merchant/utils/AppAvatar';
import { IconButton, Radio, Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Link from 'next/link';
import {
  useCategoriesTree,
  useSupercategorySummary,
} from '../../state/actions/categoriesAction';
import useFilterDates from '../../hooks/useFilterDates';
import s from './styles/business-layout.module.scss';

export const BusinessSideNav = ({ closeSideBar }) => {
  const merchantProfileState = useSelector(store => store.merchantProfileState);
  const {
    merchantProfile: {
      profile_photo: logo,
      merchant: {
        id: business_id,
        business_name,
        business_username,

        account_status,
      },
    },
  } = merchantProfileState;

  const { superCategorySummaryList, isLoading: loadingSuperCategoryList } =
    useSupercategorySummary({
      merchant_id: business_id,
    });
  const router = useRouter();
  const superCategory = router.query['super-category'];

  return (
    <div className={s.navigationBar}>
      {router.asPath !== `/business/${business_username}` &&
        router.asPath !==
          `/business/${business_username}?super-category=${superCategory}` && (
          <div className="grid justify-center py-4">
            <div className="w-20 h-20 justify-self-center">
              <AppAvatar
                src={logo?.url}
                alt={business_name}
                showBadge={account_status === 'claimed'}
              />
            </div>

            <div className="font-semibold text-sm mt-2 justify-self-center">
              {business_name}
            </div>
            <div className="text-gray-400 font-semi-bold text-xs justify-self-center">
              @{business_username}
            </div>
            <div className="mt-2 justify-self-center">
              <Link href={`/business/${business_username}`}>
                <a onClick={() => closeSideBar && closeSideBar()}>
                  <button className="custom-button lc sm outlined with-icon">
                    {(router.asPath === `/business/${business_username}` ||
                      router.asPath ===
                        `/business/${business_username}?super-category=${superCategory}`) && (
                      <span className="material-icons !text-sm">&#xe5d8;</span>
                    )}

                    <span>View full profile</span>
                  </button>
                </a>
              </Link>
            </div>
          </div>
        )}

      <div>
        {superCategorySummaryList
          ?.filter(superCategory => superCategory.offerings_count > 0)
          ?.map(superCategory => (
            <div className={s.section} key={superCategory.id}>
              <SideBarTab
                superCategory={superCategory}
                merchant_id={business_id}
                closeSideBar={() => closeSideBar && closeSideBar()}
              />
            </div>
          ))}
        {loadingSuperCategoryList && (
          <div className="text-center">
            {[1, 2, 3].map(key => (
              <div key={key} className="m-4 flex items-center">
                <Skeleton width="100%" />
                <IconButton>
                  <span className="material-icons">&#xe5cf;</span>
                </IconButton>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const SideBarTab = ({ superCategory, merchant_id, closeSideBar }) => {
  const router = useRouter();
  const category = router.query['super-category'];
  const [open, setOpen] = useState(true);

  return (
    <div className={cn(s.title, 'flex items-center space-x-2')}>
      <div>
        <div className="flex items-center space-x-2">
          <span
            className={cn('font-semibold', {
              'text-primary font-bold': category === superCategory.slug,
            })}
            onClick={() => {
              superCategory.slug !== category &&
                router.push({
                  query: {
                    b_username: router.query.b_username,
                    'super-category': superCategory.slug,
                  },
                });
            }}
          >
            {superCategory.name}
          </span>
          <IconButton
            onClick={() => setOpen(open => !open)}
            color={superCategory.slug === category ? 'primary' : undefined}
          >
            {open ? (
              <span className="material-icons">&#xe5ce;</span>
            ) : (
              <span className="material-icons">&#xe5cf;</span>
            )}
          </IconButton>
        </div>
        {open && (
          <CategoryList
            super_category_slug={superCategory.slug}
            merchant_id={merchant_id}
            closeSideBar={closeSideBar}
          />
        )}
      </div>
    </div>
  );
};

const CategoryList = ({ super_category_slug, merchant_id, closeSideBar }) => {
  const router = useRouter();

  const {
    category,
    parentCategory: parentCategoryParam,
    subCategory: subCategoryParam,
    b_username,
    happening,
  } = router.query;

  const { event_start_time, event_end_time } = useFilterDates({ happening });

  const superCategory = router.query['super-category'];

  const { data, isLoading } = useCategoriesTree({
    super_category_slug,
    merchant_id,
    preventFetch: !super_category_slug || !merchant_id,
    event_start_time,
    event_end_time,
    content_filter_type: superCategory === 'events' ? 'events' : undefined,
  });

  return (
    <div>
      {data?.map(mainCategoryObject => {
        const mainCategory = mainCategoryObject.merchant_category;
        return (
          <div key={mainCategory.id} onClick={() => closeSideBar()}>
            <CategoryLink
              category={mainCategory}
              href={{
                pathname: '/business/[b_username]',
                query: {
                  b_username,
                  'super-category': super_category_slug,
                  category: mainCategory.slug,
                },
              }}
              active={category === mainCategory.slug}
            />
            <div className="pl-6">
              {mainCategoryObject.sub_categories?.map(parentCategoryObject => {
                const parentCategory = parentCategoryObject.merchant_category;
                return (
                  <div key={parentCategory.id}>
                    <CategoryLink
                      category={parentCategory}
                      href={{
                        pathname: '/business/[b_username]',
                        query: {
                          b_username,
                          'super-category': super_category_slug,
                          category: mainCategory.slug,
                          parentCategory: parentCategory.slug,
                        },
                      }}
                      active={parentCategoryParam === parentCategory.slug}
                    />
                    <div className="pl-6">
                      {parentCategoryObject.sub_categories?.map(subCategory => {
                        return (
                          <CategoryLink
                            key={subCategory.id}
                            category={subCategory}
                            href={{
                              pathname: '/business/[b_username]',
                              query: {
                                b_username,
                                'super-category': super_category_slug,
                                category: mainCategory.slug,
                                parentCategory: parentCategory.slug,
                                subCategory: subCategory.slug,
                              },
                            }}
                            active={subCategoryParam === subCategory.slug}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {isLoading &&
        [1, 2, 3].map(key => (
          <div key={key}>
            <span className="text-gray-600 visited:text-gray-600 hover:text-black flex items-center w-full">
              <Radio size="small" />
              <Skeleton width="100%" />
            </span>
          </div>
        ))}
    </div>
  );
};

const CategoryLink = ({ category, href, active }) => {
  return (
    <div>
      <Link href={href}>
        <a className="text-gray-600 visited:text-gray-600 hover:text-black">
          <Radio checked={active} size="small" />
          <span>{category.name}</span>
        </a>
      </Link>
    </div>
  );
};

export default BusinessSideNav;
