import { DEFINED_DATES } from '@ictlife/core/constants';
import {
  endOfMonth,
  endOfThisSunday,
  endOfWeek,
  startOfMonth,
  startOfThisSaturday,
  startOfWeek,
} from '@ictlife/core/utils/dateFunctions';

import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTimeOfDay } from './useTimeOfDay';

const useFilterDates = ({ happening }) => {
  const { startDate: dayStartDate, endDate: dayEndDate } = useTimeOfDay({
    happening,
  });

  const dateDate = useMemo(() => {
    if (happening) {
      let startDate, endDate;

      switch (happening) {
        case DEFINED_DATES.today.value: {
          startDate = dayjs().hour(0).minute(0).second(0).millisecond(0);
          endDate = dayjs().hour(23).minute(59).second(59).millisecond(999);
          break;
        }

        case DEFINED_DATES.thisWeek.value: {
          startDate = startOfWeek();
          endDate = endOfWeek();
          break;
        }

        case DEFINED_DATES.thisWeekend.value: {
          startDate = startOfThisSaturday();
          endDate = endOfThisSunday();
          break;
        }

        case DEFINED_DATES.thisMonth.value: {
          startDate = startOfMonth();
          endDate = endOfMonth();
          break;
        }

        default: {
          const dates = happening.split('-until-') ?? [];
          startDate = dayStartDate || dayjs(dates[0]);
          endDate =
            dayEndDate ||
            dayjs(dates[1] || startDate)
              .hour(23)
              .minute(59)
              .second(59)
              .millisecond(999);
          break;
        }
      }

      if (startDate?.isValid() && endDate?.isValid()) {
        return {
          event_start_time: startDate.valueOf(),
          event_end_time: endDate.valueOf(),
          startDate,
          endDate: endDate.diff(startDate, 'day') !== 0 ? endDate : null,
          ranges: [
            {
              startDate: dayjs(startDate).toDate(),
              endDate: dayjs(endDate).toDate(),
              key: 'selection',
            },
          ],
        };
      }
    }

    return {
      event_start_time: dayjs().valueOf(),
      event_end_time: dayjs().add(5, 'years').valueOf(),
      ranges: [
        {
          startDate: dayjs().toDate(),
          endDate: dayjs().toDate(),
          key: 'selection',
        },
      ],
    };
  }, [dayEndDate, dayStartDate, happening]);

  return dateDate;
};

export default useFilterDates;
