import dayjs from 'dayjs';

export function startOfThisSaturday(inputDate) {
  const date = inputDate || dayjs();
  const day = date.get('day');
  let delta;
  delta = 6 - day;
  if (day === 0) delta = -1;

  return date.hour(0).minute(0).second(0).millisecond(0).add(delta, 'days');
}
